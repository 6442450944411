import React, { useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import './Home.css';
import poster from './mgb_poster_2025.jpg';


function Home() {
  useEffect(() => {
    const countdown = () => {
      const targetDate = new Date('2025-08-13T14:00:00+02:00').getTime();
      const now = new Date().getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      document.getElementById('countdown').innerHTML =
        days + 'D ' + hours + 'H ' + minutes + 'M ' + seconds + 'S ';

      if (distance < 0) {
        clearInterval(intervalId);
        document.getElementById('countdown').innerHTML = 'The festival has started!';
      }
    };

    const intervalId = setInterval(countdown, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="Home">
      <Navbar />
      <header className="Home-header">
        <div className="hero-section">
          <video autoPlay muted loop className="hero-video">
            <source src="https://www.midgardsblot.no/img/video/homepage.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="hero-overlay">
            
            <div className="countdown-timer">
              <img alt="border" src="https://midgardsblot.no/resources/assets/border.png"/>
              <h2 className='norse-font' id="countdown"> </h2>
              <img alt="border" src="https://midgardsblot.no/resources/assets/border.png"/>
              
            </div>
            <p className='norse-font'>Join us for mysteries of the past, melodies of the present, and dive into the heart of Midgardblot with us!</p>
            <p>Blind bird tickets in sale now!</p>
            <a href="https://www.ticketmaster.no/artist/midgardsblot-tickets/1197464?language=en-us" rel="noopener noreferrer" target="_blank" className="norse-font-bold cta-button">Get your tickets!</a>
            </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <div className="lineup-grid">
           
            <div>
              <h3 className="norse-font">WELCOME HOME!</h3>
              <hr className="sep"></hr>
              <p>Midgardsblot takes place in Midgard, a place for all human beings, no matter what nationality, cultural background, ethnicity, gender or sexual orientation ❤ Our festival and our community are neutral grounds where we welcome you all to meet, to bond and to build friendship across all borders. A place where we celebrate all our different roots and our diversity so we all can share wisdom, learn from each other and learn to accept our differences. To do so we have a zero tolerance on politics and harrasments of any kind so you must all leave that at our Gates before entering.

</p>
<p>Until we meet again,<br/>
Love, honor, and peace,<br/>
Midgardsblot</p>

            </div>
            <div>
              <img style={{ width: '100%' }} src={poster} alt="Festival Lineup"></img>
           
            </div>
            <div>
              <h3 className="norse-font">TICKET PRICES WILL SOON RISE!</h3>
              <hr className="sep"></hr>
              <p>🎟️ Act now – ticket prices will increase soon! Secure your spot at Midgardsblot 2025 before the next price hike and save your place at this one-of-a-kind festival.
</p><p>
Stay tuned for updates and make your plans now for a week of music, learning and nature. Whether you're a die-hard metal fan, a history enthusiast, or looking for an unforgettable family experience, Midgardsblot has it all. ⚔️🔥</p>
<a href="https://www.ticketmaster.no/artist/midgardsblot-tickets/1197464?language=en-us" rel="noopener noreferrer" target="_blank" className="norse-font-bold cta-button">Get your tickets!</a>
            </div>
          </div>
        </section>
        <section className='red-section'>
        
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
