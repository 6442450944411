import React, { useState } from 'react';
import './Navbar.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'; // For free solid icons
import { fab } from '@fortawesome/free-brands-svg-icons'; // For free brand icons
import { far } from '@fortawesome/free-regular-svg-icons'; // For free regular icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(fas, fab, far);

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <button className="navbar-toggle" onClick={toggleNavbar}>
        ☰
      </button>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        
        <a href="/information" className=" norse-font-bold">Information</a>
        <a href="/lineup" className=" norse-font-bold">Lineup</a>
        <a href="/folkvangr" className=" norse-font-bold">Folkvangr</a>

      </div>
      <div className="navbar-logo fade-in-image">
      <a href="/"> <img className="" src={`${process.env.PUBLIC_URL}/assets/Midgard Borredyret logo.png`} alt="Midgardsblot Logo" />
      </a>
      </div>
      <div className={`navbar-links ${isOpen ? 'open-end' : ''}`}>
        
        <b className='norse-font'>13.-16. August</b>
        <a href="https://www.ticketmaster.no/artist/midgardsblot-tickets/1197464?language=en-us" rel="noopener noreferrer" target="_blank" className="norse-font-bold cta-button-nav">Buy Tickets</a>
        <a href="https://store.midgardsblot.no/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fas', 'fa-cart-shopping']} /> </a>
        <a href="https://www.facebook.com/midgardsblot" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook']} /> </a>
        <a href="https://www.instagram.com/midgardsblot/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']} /> </a>
        <a href="https://www.youtube.com/c/MidgardsblotMetalFestival" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} /> </a>
      
      </div>
      <div className="navbar-border navbar-border-left"></div>
      <div className="navbar-border navbar-border-right"></div>
    </nav>
  );
}

export default Navbar;
