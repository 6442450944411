import React, { useEffect, useState } from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import axios from 'axios';
import './Lineup.css';

function Lineup() {
  const [bands, setBands] = useState([]);

  useEffect(() => {
    axios.get('/bands2025.json')  // Ensure the path to your JSON file is correct
      .then(response => {
        setBands(response.data);
      })
      .catch(error => {
        console.error('Error fetching bands:', error);
      });
  }, []);

  return (
    <div className="Information">
      <Navbar />
      <div className="video-background">
        <video autoPlay loop muted>
          <source src="https://www.midgardsblot.no/img/video/rune.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <main>
        <section className="daysplit-section" style={{ marginTop: '2em' }}>
          {bands.map((band, index) => (
            <div key={index} className="band-container">
              <a href={`/program/bands/${band.name.replace(/\s+/g, '-').toLowerCase()}`}>
                <img src={band.logo} alt={`${band.name} Logo`} className="band-logo" />
              </a>
            </div>
          ))}
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Lineup;
